<template>
  <el-card
      shadow="hover"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      style="height: 100%;overflow-y: auto;"
  >
  <!-- 游戏配置与调控--游戏列表 -->
  <div id="ListBox">
    <div class="TitleBox">
      <p  class="title">
        <font style="vertical-align: inherit;">
          <font style="vertical-align: inherit;">游戏配置与调控--游戏列表</font>
        </font>
      </p>
        <div class="AddList">

          <el-button
                  type="success"
                  icon="el-icon-circle-plus-outline"
                  round
                  @click="config"
          >公共库配置</el-button>

          <el-button
                  type="success"
                  icon="el-icon-circle-plus-outline"
                  round
                  @click="addDialogVisible = true"
          >新增游戏列表</el-button>

        </div>
    </div>
    <div class="TabelBox">
      <!-- 表格 -->
      <el-table :data="GameList" style="width: 100%">
        <el-table-column label="GameID" sortable prop="gameid"></el-table-column>
        <el-table-column label="游戏名称" prop="gamename"></el-table-column>
        <el-table-column label="游戏分类" prop="sort">
          <template slot-scope="scope">
            <span>{{scope.row.sort}}</span>
          </template>
        </el-table-column>
        <el-table-column label="排序" sortable prop="sortorder"></el-table-column>
        <el-table-column label="热度" prop="hottype">
          <template slot-scope="scope">
            <span>{{scope.row.hottype===0?'普通 ':scope.row.hottype===1?'新游':scope.row.hottype===2?'热门':scope.row.sort}}</span>
          </template>
        </el-table-column>
        <el-table-column label="种子在线" prop="hottype">
          <template slot-scope="scope">
            <span>{{scope.row.player_min}}/</span>
            <span>{{scope.row.player_max}}</span>
          </template>
        </el-table-column>
        <el-table-column label="状态" prop="status">
          <template slot-scope="scope">
            <span v-if="scope.row.status === 0">正常</span>
            <span v-else-if="scope.row.status === 1">维护但不显示</span>
            <span v-else-if="scope.row.status === 2">维护但显示</span>
            <span v-else>正常但不显示</span>
          </template>
        </el-table-column>
        <el-table-column label="游戏地址" width="300" prop="addr"></el-table-column>
        <el-table-column label="跳转地址" prop="jump_tag">
          <template slot-scope="scope">
            <span>{{scope.row.jump_tag===0?'大厅':'默认'}}</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="250" class="btns">
          <template slot-scope="scope">
            <el-button size="mini" type="info" round @click="banbengl(scope.row)">版本管理</el-button>
            <el-button size="mini" v-show="scope.row.gameid != 0" type="primary" round @click="showEditDialog(scope.row)">编辑</el-button>
<!--            <el-button size="mini" type="danger" round @click="removeUserById(scope.row)">删除</el-button>-->
            <el-button size="mini"  type="success" round @click="open(scope.row)">状态</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页器 -->
      <div class="page">
        <!-- <span>每页10条,</span> -->
        <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryInfo.page"
                :page-size="queryInfo.pagenum"
                :page-sizes="[20, 50, 100, 200,500]"
                style="float:right"
                layout="sizes,total, prev, pager, next, jumper"
                :total="total"
        ></el-pagination>
        <el-button type="primary" @click="change(queryInfo.page)">确定</el-button>
      </div>

      <!-- 添加游戏列表对话框 -->
      <el-dialog
        :title="'添加新游戏列表'"
        style="text-align:left !important"
        :close-on-click-modal="false"
        :visible.sync="addDialogVisible"
      >
        <!-- 内容区域 -->
        <el-form ref="addFormRef" label-position="right" :model="addForm" :rules="addFormRules">

<!--          <el-row type="flex" class="row-bg" justify="start">-->
            <el-row type="flex" class="row-bg" justify="space-around">
            <el-col :span="8">
              <el-form-item label="GameID" prop="gameid">
                <el-input v-model.number="addForm.gameid" placeholder></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="游戏名称" prop="gamename">
                <el-input v-model="addForm.gamename" placeholder></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="游戏分类"  prop="sort">
                <el-select v-model="addForm.sort" multiple collapse-tags>
                  <el-option label="经典棋牌" value="1"></el-option>
                  <el-option label="牛牛专区" value="2"></el-option>
                  <el-option label="街机捕鱼" value="3"></el-option>
                  <el-option label="百人大战" value="4"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row type="flex" class="row-bg" justify="space-around">
            <el-col :span="8">
              <el-form-item label="排序" prop="sortorder">
                <el-input v-model.number="addForm.sortorder" placeholder></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="热度" prop="hottype">
                <el-select v-model="addForm.hottype">
                  <el-option label="普通" value="0"></el-option>
                  <el-option label="新游" value="1"></el-option>
                  <el-option label="热门" value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="游戏地址" prop="addr">
                <el-input v-model="addForm.addr"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row type="flex" class="row-bg" justify="space-around">
            <el-col :span="8">
              <el-form-item label="状态" prop="status">
                <el-select v-model="addForm.status">
                  <el-option label="正常" value="0"></el-option>
                  <el-option label="维护但不显示" value="1"></el-option>
                  <el-option label="维护但显示" value="2"></el-option>
                  <el-option label="正常但不显示" value="3"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="种子在线最小人数" prop="player_min" :inline="true">
                <el-input
                   v-model.number="addForm.player_min"
                   placeholder
                   @blur="player_min($event)"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="player_max" label="种子在线最大人数">
                <el-input
                  v-model.number="addForm.player_max"
                  placeholder
                  @blur="player_max($event)"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row type="flex" class="row-bg" justify="space-around">
            <el-col :span="8">
              <el-form-item label="跳转地址" prop="jump_tag">
                <el-select v-model="addForm.jump_tag">
                  <el-option label="默认" value="0"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

        </el-form>
        <!-- 按钮区域 -->
        <span slot="footer" class="dialog-footer">
          <el-button type="success" @click="addList">提交</el-button>
          <el-button type="primary" @click="addDialogVisible = false">取消</el-button>
        </span>
      </el-dialog>

      <!-- 编辑游戏列表对话框 -->
      <el-dialog
        :title="'编辑游戏列表'"
        style="text-align:left !important"
        :close-on-click-modal="false"
        :visible.sync="editDialogVisible"
      >
        <!-- 内容部分 -->
        <el-form ref="editFormRef" :model="editForm" :rules="editFormRules">
          <el-row type="flex" class="row-bg" justify="start">
            <el-col :span="8">
              <el-form-item label="GameID" prop="gameid">
                <el-input v-model.number="editForm.gameid" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="游戏名称" prop="gamename">
                <el-input v-model="editForm.gamename"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="游戏分类" prop="sort">
                <el-select v-model="editForm.sort" multiple collapse-tags>
                  <el-option label="经典棋牌" :value="1"></el-option>
                  <el-option label="牛牛专区" :value="2"></el-option>
                  <el-option label="街机捕鱼" :value="3"></el-option>
                  <el-option label="百人大战" :value="4"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" class="row-bg" justify="start">
            <el-col :span="8">
              <el-form-item label="热度" prop="hottype" class="label">
                <el-select v-model="editForm.hottype">
                  <el-option label="普通" :value="0"></el-option>
                  <el-option label="新游" :value="1"></el-option>
                  <el-option label="热门" :value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="种子在线最小人数" prop="player_min" :inline="true">
                <el-input
                    v-model.number="editForm.player_min"
                    placeholder
                    @blur="player_min1($event)"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="player_max" label="种子在线最大人数">
                <el-input
                    v-model.number="editForm.player_max"
                    placeholder
                    @blur="player_max2($event)"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" class="row-bg" justify="start">
            <el-col :span="8">
              <el-form-item label="跳转地址" prop="jump_tag">
                <el-select v-model="editForm.jump_tag">
                  <el-option label="大厅" :value="0"></el-option>
                  <el-option label="默认" :value="1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="游戏地址" prop="addr">
                <el-input v-model="editForm.addr"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="排序" prop="sortorder">
                <el-input v-model.number="editForm.sortorder"></el-input>
              </el-form-item>
            </el-col>
          </el-row>


          <!--福新高照参数-->
          <el-row type="flex" v-if="editForm.gameid == 888" class="row-bg" justify="start">
            <el-col :span="6">
              <el-form-item label="小奖池初始值" prop="im1">
                <el-input v-model.number="editForm.im1"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="中奖池初始值" prop="im2">
                <el-input v-model.number="editForm.im2"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="大奖池初始值" prop="im3">
                <el-input v-model.number="editForm.im3"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="巨奖池初始值" prop="im4">
                <el-input v-model.number="editForm.im4"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row type="flex" v-if="editForm.gameid == 888" class="row-bg" justify="start">
            <el-col :span="6">
              <el-form-item label="小奖池增量值" prop="rm1">
                <el-input v-model="editForm.rm1"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="中奖池增量值" prop="rm2">
                <el-input v-model="editForm.rm2"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="大奖池增量值" prop="rm3">
                <el-input v-model="editForm.rm3"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="巨奖池增量值" prop="rm4">
                <el-input v-model="editForm.rm4"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

        </el-form>
        <!-- 按钮区域 -->
        <span slot="footer" class="dialog-footer">
          <el-button type="success" @click="editList">提交</el-button>
          <el-button type="primary" @click="editDialogVisible = false">取消</el-button>
        </span>
      </el-dialog>

      <!-- 修改游戏状态 -->
      <el-dialog class="loading-dialog"
                 title="状态设置"
                 :visible.sync="dialogVisible"
                 width="40%"
                 :show-close="true">
        <el-form ref="elForm" :model="changeStatus" size="medium" label-width="100px">
          <template>
            <el-radio v-model="changeStatus.status" :label="0">正常</el-radio>
            <el-radio v-model="changeStatus.status" :label="1">维护但不显示</el-radio>
            <el-radio v-model="changeStatus.status" :label="2">维护但显示</el-radio>
            <el-radio v-model="changeStatus.status" :label="3">正常但不显示</el-radio>
          </template>
        </el-form>
        <div slot="footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="doUpdate">确 定</el-button>
        </div>
      </el-dialog>


    </div>
  </div>
  </el-card>
</template>

<script>
import { Encrypt, Decrypt } from "../../assets/js/utils";
export default {
  data() {
    return {
      loading: true,
      gamenames: "",
      addr: "",
      version: "",
      changeStatus:{
         gameid :"",
         status :"",
      },
      queryInfo: {
        page: 1,
        pagenum: 20
      },
      total: 0,
      GameList: [],
      addDialogVisible: false,
      editDialogVisible: false,
      dialogVisible:false,
      addForm: {
        gameid: "",
        gamename: "",
        sort: [],
        status: "",
        player_min: "",
        player_max: "",
        hottype: "",
        jump_tag:""
      },
      // 添加验证表单对象
      addFormRules: {
        gameid: [
                { required: true, message: "请输入ID", trigger: "blur" },
                { type: "number", message: "值必须为数字" }
          ],
        gamename: [
          { required: true, message: "请输入游戏名称", trigger: "blur" }
        ],
        sort: [{ required: true, message: "请输入游戏类型", trigger: "blur" }],
        sortorder: [
                { required: true, message: "排序", trigger: "blur" },
                { type: "number", message: "值必须为数字" }],
        jump_tag: [
          { required: true, message: "请选择跳转地址", trigger: "blur" }
        ],
        status: [{ required: true, message: "请选择状态", trigger: "blur" }],
        player_min: [
          { required: true, message: "请填写", trigger: "blur" },
          ,
          { type: "number", message: "值必须为数字" }
        ],
        player_max: [
          { required: true, message: "请填写", trigger: "blur" },
          ,
          { type: "number", message: "值必须为数字" }
        ],
        addr: [
          { required: true, message: "请输入游戏地址,IP+端口", trigger: "blur" }
        ],
        hottype: [{ required: true, message: "热度", trigger: "blur" }]
      },
      editForm: {
        gameid: "",
        addr: "",
        player_min: "",
        player_max: "",
        status: "",
        jump_tag: "",
        hottype: "",
      //  福新高照
        //初始金额
        im1:"",
        im2:"",
        im3:"",
        im4:"",
        //增量值
        rm1:"",
        rm2:"",
        rm3:"",
        rm4:""
      },
      // 添加编辑表单对象
      editFormRules: {
        gamename: [
          { required: true, message: "请输入游戏名称", trigger: "blur" }
        ],
        sort: [{ required: true, message: "请输入游戏类型", trigger: "blur" }],
        sortorder: [{ required: true, message: "排序", trigger: "blur" },
          { type: "number", message: "值必须为数字" }],
        addr: [
          { required: true, message: "请输入游戏地址,IP+端口", trigger: "blur" }
        ],
        hottype: [{ required: true, message: "请选择", trigger: "blur" }],
        status: [{ required: true, message: "请选择", trigger: "blur" }],
        player_min: [
                { required: true, message: "请选择", trigger: "blur" },
                { type: "number", message: "值必须为数字" }],
        player_max: [
                { required: true, message: "请选择", trigger: "blur" },
                { type: "number", message: "值必须为数字" }],
        jump_tag: [{ required: true, message: "请选择", trigger: "blur" }],

        im1:[
          { required: true, message: "请填写", trigger: "blur" },
          { type: "number", message: "值必须为数字" }],
        im2:[
          { required: true, message: "请填写", trigger: "blur" },
          { type: "number", message: "值必须为数字" }],
        im3:[
          { required: true, message: "请填写", trigger: "blur" },
          { type: "number", message: "值必须为数字" }],
        im4:[
          { required: true, message: "请填写", trigger: "blur" },
          { type: "number", message: "值必须为数字" }],
        //增量值
        rm1:[{ required: true, message: "请填写", trigger: "blur" },],
        rm2:[{ required: true, message: "请填写", trigger: "blur" },],
        rm3:[{ required: true, message: "请填写", trigger: "blur" },],
        rm4:[{ required: true, message: "请填写", trigger: "blur" },],
      }
    };
  },
  mounted() {
    this.getGameList();
  },
  methods: {
    player_min(e) {
      var obj = e.target.value;
      if (this.addForm.player_max != "") {
        if (obj >= this.addForm.player_max) {
          this.$message.error("值不能大于等于种子在线最大人数");
          this.addForm.player_min = "";
        }
      }
    },
    player_max(e) {
      var obj = e.target.value;
      if (obj <= this.addForm.player_min) {
        this.$message.error("值不能小于等于种子在线最小人数");
        this.addForm.player_max = "";
      }
    },
    player_min1(e) {
      var obj = e.target.value;
      if (this.editForm.player_max != "") {
        if (obj >= this.editForm.player_max) {
          this.$message.error("值不能大于等于种子在线最大人数");
          this.editForm.player_min = "";
        }
      }
    },
    player_max2(e) {
      var obj = e.target.value;
      if (obj <= this.editForm.player_min) {
        this.$message.error("值不能小于等于种子在线最小人数");
        this.editForm.player_max = "";
      }
    },
    change(newPage) {
      this.loading = false;
      this.handleCurrentChange(newPage);
      this.loading = true;
    },
    // 监听pageName改变的事件
    handleSizeChange(pagenum) {
      this.loading = false;
      this.queryInfo.pagenum = pagenum;
      this.getGameList();
      this.loading = true;
    },
    // 监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.loading = false;
      this.queryInfo.page = newPage;
      this.getGameList();
      this.loading = true;
    },

    //删除时，获取最新页码
    setPage() {
      let that = this;
      // this.getCount(); //从新获取总数
      let page = Math.ceil((that.total - 1) / that.queryInfo.pagenum); //向上取整计算页数
      let nowpage = this.queryInfo.page > page ? page : that.queryInfo.page;
      that.queryInfo.page = nowpage < 1 ? 1 : nowpage;
      // this.getPage(); //获取数据
    },

    // 获取游戏列表
    getGameList() {
      var params = this.queryInfo;
      let that = this;
      this.$Http({
        url: "/api/gamebase/gameList",
        params,
        successful(res, data) {
          that.loading = false;
          data.data.data.forEach((v,k)=>{
            var sort = v.sort;
            var s = 1;
            var t = "";
            while (sort){
              if((sort & 1) > 0 ){
                var str = s==1?'经典棋牌 ':s==2?'牛牛专区':s==3?'街机捕鱼':s==4?'百人大战':s
                t += str +",";
              }
              s += 1;
              sort = sort >> 1;
            }
            v.sort = (t.substring(t.length-1)==',')?t.substring(0,t.length-1):t;
          })
          that.GameList = data.data.data;
          that.total = data.data.total;
        }
      });
    },
    // 点击添加列表提交按钮添加列表
    addList() {
      var params = this.addForm;
      this.$refs.addFormRef.validate(valid => {
        if (!valid) return;
        //  添加请求
        let that = this;
        this.$Http({
          url: "/api/gamebase/create",
          params,
          successful(res, data) {
            if (data.status == false) {
              that.$message.error(data.message);
            } else {
              that.getGameList();
              that.addDialogVisible = false;
              that.$message.success("添加成功！");
            }
          }
        });
      });
    },
    // 编辑按钮
    showEditDialog(row) {
      this.editForm.gameid = row.gameid;
      var params = { gameid: row.gameid };
      let that = this;
      this.$Http({
        url: "/api/gamebase/getById",
        params,
        successful(res, data) {
          that.editForm = data.data;
          that.editDialogVisible = true;
        }
      });
    },
    // 编辑并提交信息
    editList() {
      var params = this.editForm;
      this.$refs.editFormRef.validate(valid => {
        //  添加请求
        if(params.gameid == 888) {
          var obj = JSON.parse(JSON.stringify(this.editForm));
          try {
            if (!valid) {
              throw {message: "参数值为空", value: []};
            }
            // if (obj.im1 < 0 || obj.im1 > 10000) {
            //   throw {
            //     message: "小奖池初始值在0-10000之间",
            //     value: [0, 10000]
            //   };
            // }

            // if (obj.im2 < 0 || obj.im2 > 100000) {
            //   throw {
            //     message: "中奖池初始值在0-100000之间",
            //     value: [0, 100000]
            //   };
            // }

            // if (obj.im3 < 0 || obj.im3 > 100000) {
            //   throw {
            //     message: "大奖池初始值在0-100000之间",
            //     value: [0, 100000]
            //   };
            // }
            //
            // if (obj.im4 < 0 || obj.im4 > 100000) {
            //   throw {
            //     message: "巨奖池初始值在0-100000之间",
            //     value: [0, 100000]
            //   };
            // }

            // if (obj.rm1 < -100000 || obj.rm1 > 100000) {
            //   throw {
            //     message: "小奖池增量值在-100000-100000之间",
            //     value: [-100000, 100000]
            //   };
            // }

            // if (obj.rm2 < -100000 || obj.rm2 > 100000) {
            //   throw {
            //     message: "中奖池增量值在-100000-100000之间",
            //     value: [-100000, 100000]
            //   };
            // }

            // if (obj.rm3 < -100000 || obj.rm3 > 100000) {
            //   throw {
            //     message: "大奖池增量值在-100000-100000之间",
            //     value: [-100000, 100000]
            //   };
            // }

            // if (obj.rm4 < -100000 || obj.rm4 > 100000) {
            //   throw {
            //     message: "巨奖池增量值在-100000-100000之间",
            //     value: [-100000, 100000]
            //   };
            // }

          } catch (e) {
            return this.$message.error(e.message);
          }
          if(obj.rm1 < 0 || obj.rm2 < 0 || obj.rm3 < 0 || obj.rm4 < 0){
            this.$confirm('Jackpot增量值为负数，将会重置对应的奖池余额，您确定要提交吗？', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.do_update(params);
            }).catch(() => {
              return this.$message.info("已取消修改");
            });
          }else{
            this.do_update(params);
          }
        }else {
          this.do_update(params);
        }
      });
    },

    do_update(params){
      let that = this;
      this.$Http({
        url: "/api/gamebase/update",
        params,
        successful(res, data) {
          if (data.status == false) {
            that.$message.error(data.message);
          } else {
            that.editDialogVisible = false;
            that.getGameList();
            if(params.gameid == 888){
              var msg;
              if(data.data == 0){
                msg = '基本信息未修改,'+data.message;
              }else {
                msg = '基本信息修改成功,'+data.message;
              }
              that.$message.success(msg);
            }else {
              that.$message.success("编辑成功");
            }

          }
        }
      });
    },
    // 根据id删除信息
    removeUserById(row) {
      var params = {gameid:row.gameid};
      let that = this;
      // 根据弹框删除
      const confirmResult = this.$confirm(
        "此操作将删除该游戏，是否继续？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }
      )
      .then(() => {
        if(row.is_close == false){
          that.$message.error("请先设置该游戏为关闭状态，再进行删除操作！");
        }else{
          this.$Http({
            url: "/api/gamebase/delete",
            params,
            successful(res, data) {
              that.setPage();
              that.$message.success("删除成功");
              that.getGameList();
            }
          });
        }
      })
      .catch(() => {
        that.$message.info("已取消删除");
      });
    },

    open(row){
        this.changeStatus.gameid = row.gameid
        this.changeStatus.status = row.status;
        this.dialogVisible = true;
    },
    doUpdate() {
      let that = this;

      // 根据弹框停用
      var params = that.changeStatus;
      const confirmResult = this.$confirm("提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        message: "此操作将修改该游戏状态，是否继续？"
      })
        .then(() => {
          this.$Http({
            url: "/api/gamebase/changeStatus",
            params,
            successful(res, data) {
              that.dialogVisible = false;
              if(data.status == true){
                that.$message.success(data.data);
                that.getGameList();
              }else {
                that.$message.error(data.message);
              }
            }
          });
        })
        .catch(() => {
          that.$message.info("已取消");
        });
    },
    // 版本管理按钮
    banbengl(row) {

      if(row.version_info != ""){
        var version = JSON.parse(row.version_info).version;
        var gamename = JSON.parse(row.version_info).gamename;
        var addrd = JSON.parse(row.version_info).addr;
        row.version =  version == undefined ? "" : version;
        row.gamename = gamename == undefined ? "" : gamename;
        row.addrd = addrd == undefined ? "" : addrd;
      }
      this.$router.push({
        name: "Manager",
        params:row
      });
    },
    // 公共库配置
    config() {
      this.$router.push({ path: "/Publicconfig" });
    }
  }
};
</script>

<style lang="less" scoped>

  #ListBox .TitleBox .AddList button {
    font-weight: 700;
    background: #6495ed;
    border: 1px solid grey;
    border-radius: 2px;
    padding: 5px;
    cursor: pointer;
    color: #f5f5f5;
  }

  #ListBox .el-form /deep/ .el-input__inner {
    width: 200px!important;
  }

  .el-row--flex.is-justify-space-around {
    justify-content: end!important;
  }
  #ListBox .TitleBox {
    justify-content: space-between;
    margin-bottom: 20px;
  }
  #ListBox .TitleBox, .page {
    display: flex;
    align-items: center;
  }
  #ListBox .TitleBox .title {
    font-weight: 700;
    font-size: 20px;
    display: flex;
    align-items: center;
  }
  .page {
    font-weight: 400;
    color: #606266;
    font-size: 13px;
    line-height: 28px;
    justify-content: flex-end;
  }

</style>

<style>
  #ListBox .el-form .el-form-item__label {
    float: none;
  }

  .el-table--enable-row-transition .el-table__body td {
    border-right: 1px solid #ebeef5;
  }
</style>
<template>
  <!-- 游戏类目 -->
  <el-card
          shadow="hover"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          style="height: 100%;overflow-y: auto;"
  >
  <div v-loading="loading" style="height: 100%;">
    <el-card>
      <el-row justify="end" type="flex">
        <el-col>
          <h3>游戏类目</h3>
        </el-col>

        <el-col :span="3" style="margin:15px 0">
          <el-button type="primary" class="button" @click="addlis()" icon="el-icon-circle-plus-outline" round>新建类目</el-button>
        </el-col>
      </el-row>
      <!-- 表格列表 -->
      <el-row>
        <el-table ref="singleTable" :data="tableData" style="width: 100%" height="630px">
          <el-table-column type="index" width="50" label="序号"></el-table-column>
          <el-table-column property="sort_name" label="类目名称"></el-table-column>
          <el-table-column property="gamename" label="推荐位"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="edit(scope.$index, scope.row)">编辑</el-button>
              <el-button size="mini" type="success" @click="del(scope.$index, scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <!-- 分页器 -->
      <el-row>
        <div class="page">
          <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="queryInfo.page"
                  :page-size="queryInfo.pagenum"
                  :page-sizes="[20, 50, 100, 200,500]"
                  style="float:right"
                  layout="sizes,total, prev, pager, next, jumper"
                  :total="total"
          ></el-pagination>
          <el-button type="primary" @click="change(queryInfo.page)">确定</el-button>
        </div>
      </el-row>
      <!-- 新增类目 -->
      <el-row>
        <el-dialog
          title="新建游戏类目"
          :visible.sync="addDialogVisible"
          :close-on-click-modal="false"
          width="25%"
          @close="reset('addref')"
        >

          <el-form ref="addref" :model="addform"  label-width="120px" :rules="addFormRules">
            <el-row>
              <el-col :span="8">
                <el-form-item label="类目名称：" prop="sort">
                  <el-select v-model="addform.sort" placeholder="请选择类目名称" style="width:240px">
                    <el-option
                        :label="item.sort_name"
                        v-for="(item,index) of list"
                        :key="index"
                        :value="item.sort"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="推荐位：" prop="link">
                  <el-input
                    v-model="addform.link"
                    placeholder="请填写推荐游戏ID或者链接地址"
                    style="width:240px"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item>
              <el-row type="flex" justify="end">
                <el-button @click="resetForm('addref')">取 消</el-button>
                <el-button type="primary" @click="addok('addref')">确 定</el-button>
              </el-row>
            </el-form-item>
          </el-form>
        </el-dialog>
      </el-row>
      <!-- 编辑类目 -->
<!--      :rules="editFormRules"-->
      <el-row >
        <el-dialog title="编辑游戏类目" :visible.sync="editDialogVisible" :close-on-click-modal="false" width="60%">
          <el-form ref="editform"  :model="editform" label-width="120px">
            <el-row>
              <el-col :span="8">
                <el-form-item label="类目名称：">
                  <el-input v-model="editform.sort_name" disabled style="width:240px"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="8">
                <el-form-item label="推荐位：">
                  <el-input v-model="editform.link" style="width:240px"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item>
              <el-row type="flex" justify="end">
                <el-button @click="editDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="editok()">确 定</el-button>
              </el-row>
            </el-form-item>
          </el-form>
        </el-dialog>
      </el-row>
    </el-card>
  </div>
  </el-card>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      addDialogVisible: false,
      editDialogVisible: false,
      tableData: [],
      queryInfo: {
        page: 1,
        pagenum: 20
      },
      total: 0,
      addform: {
        sort_name: "",
        link: ""
      },
      // 添加验证表单对象
      addFormRules: {
        sort_name: [{ required: true, message: "请按要求填入推荐位", trigger: "blur" }],
      },
      // 编辑表单
      editform: {
        sort_name: "",
        link: ""
      },
      list: []
    };
  },
  mounted() {
    this.gettable();
  },
  methods: {
    change(newPage) {
      this.loading = false;
      this.handleCurrentChange(newPage);
      this.loading = true;
    },
    // 监听pageName改变的事件
    handleSizeChange(pagenum) {
      this.loading = false;
      this.queryInfo.pagenum = pagenum;
      this.gettable();
      this.loading = true;
    },
    // 监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.loading = false;
      this.queryInfo.page = newPage;
      this.gettable();
      this.loading = true;
    },
    gettable() {
      let that = this;
      this.$Http({
        url: "/api/categorylist",
        successful(res, data) {
          that.loading = false;
          that.tableData = data.data.data;
          that.total = data.data.data.length;
        }
      });
    },
    // 编辑按钮
    edit(index, row) {
      this.editDialogVisible = true;
      var params = { id: row.id };
      let that = this;
      this.$Http({
        url: "api/editcategory",
        params,
        successful(res, data) {
          if (data.status == false) {
            that.$message.error(data.message);
          }
          if (data.status == true) {
            that.editform = data.data[0];
            that.$set(that.editform, "id", data.data[0].id);
          }
        }
      });
    },
    // 编辑提交按钮
    editok() {
      // this.editDialogVisible = true;
      var params = { id: this.editform.id, link: this.editform.link };
      let that = this;
      this.$Http({
        url: "/api/savecategory",
        params,
        successful(res, data) {
          if (data.status == false) {
            that.$message.error(data.message);
          }
          if (data.status == true) {
            that.$message.success(data.message);
            that.gettable();
            that.editDialogVisible = false;
          }
        }
      });
    },
    // 新增类目弹框
    addlis() {
      this.addDialogVisible = true;
      this.getlist();
    },
    // 新增确定按钮
    addok(formName) {
      var params = this.addform;
      let that = this;
      this.$Http({
        url: "/api/addcategorylist",
        params,
        successful(res, data) {
          if (data.status == false) {
            that.$message.error(data.message);
          }
          if (data.status == true) {
            that.$message.success(data.message);
            that.gettable();
            that.$refs[formName].resetFields();
            that.addDialogVisible = false;
          }
        }
      });
    },
    // 新增取消按钮
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.addDialogVisible = false;
    },
    reset(formName){
      this.$refs[formName].resetFields();
    },
    // 新增获取类目名
    getlist() {
      let that = this;
      this.$Http({
        url: "api/categorylist",
        successful(res, data) {
          that.list = data.data.select;

        }
      });
    },
    // 删除按钮
    del(index, row) {
      var params = { id: row.id };
      let that = this;
      // 根据弹框删除
      const confirmResult = this.$confirm(
        "此操作将删除该列表，是否继续？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }
      )
        .then(() => {
          this.$Http({
            url: "/api/delcategory",
            params,
            successful(res, data) {
              if (data.status == false) {
                that.$message.error(data.message);
              } else {
                that.$message.success(data.message);
                that.gettable();
              }
            }
          });
        })
        .catch(() => {
          that.$message.info("已取消删除");
        });
    }
  }
};
</script>
<style lang="less" scoped>

  #anlysis {
    /deep/.el-input__inner {
      text-align: center !important;
    }
    /*.statistics {*/
    /*    color: red;*/
    /*    font-weight: 600;*/
    /*    margin-bottom: 20px;*/
    /*  .fontstyle {*/
    /*    margin: 0 20px;*/
    /*  }*/
    /*}*/
    .gamename {
      color: #409eff;
      cursor: pointer;
    }
    .page {
      margin-top: 20px;
    }
  }


  .button {
    font-weight: 700;
    background: #6495ed;
    border: 1px solid grey;
    border-radius: 2px;
    padding: 5px;
    cursor: pointer;
    margin-top: initial;
    color: #f5f5f5;
  }

  #ListBox .TitleBox, .page {
    display: flex;
    align-items: center;
  }
  .page {
    font-weight: 400;
    color: #606266;
    font-size: 13px;
    line-height: 28px;
    justify-content: flex-end;
  }
</style>
